import Box from '@spraoi/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import ContactCTA from '../../components/ContactCTA';
import Icon from '../../components/Icon';
import SEO from '../../components/SEO';
import Section from '../../components/Section';

const ServicesPage = ({ location: { pathname } }) => (
  <>
    <SEO
      description="Enabling seamless and secure Ethernet connectivity to the field based on the Single Pair Ethernet (SPE)."
      pathname={pathname}
      title="Technology | Industrial Ethernet Solutions"
    />
    <Section sx={{ pt: 0, px: [0, null, 7] }}>  
        <Box
          sx={{
            alignItems: 'center',
            bg: 'white',
            borderRadius: [null, null, 2],
            boxShadow: 3,
            display: [null, null, null, 'flex'],
            justifyContent: 'space-between',
            mt: ['1px', null, 7],
            p: [6, null, null, 7],
          }}
        >
          <Box>
            <h3>Technology</h3>
            <Box as="p" sx={{ color: 'text.subtle', lineHeight: 2, mt: 4 }}>
            Zemfyre develops and manufactures innovative industrial communication solutions based on revolutionary Single Pair Ethernet 
            (SPE) and Zemfyre’s patent-pending technology. 
            <br></br>
            <br></br>
            This technology:<br></br>
            &#x2022;&#32;Enables OT/IT convergence<br></br>
            &#x2022;&#32;Provides seamless and secure cloud connectivity to field level devices (sensors, actuators) <br></br>
            &#x2022;&#32;Extends Ethernet reach to 1,700m, instead of 100m <br></br>
            &#x2022;&#32;Powers devices using PoDL standard <br></br>
            &#x2022;&#32;Removes expensive Gateways <br></br>
            &#x2022;&#32;Enables IIoT’s predictive maintenance<br></br>
            &#x2022;&#32;Reduces downtime, increases reliability<br></br>

            </Box>
            <Box as="p" sx={{ color: 'text.subtle', lineHeight: 2, mt: 4 }}>
            
            </Box>
          </Box>
        </Box>
      </Section>
      <ContactCTA />
    </>
  );

ServicesPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default ServicesPage;
